@font-face {
  font-family: Kotex-Bold;
  src: url('../public/fonts/Kotex-Bold.otf');
}
@font-face {
  font-family: Kotex-Light;
  src: url('../public/fonts/Kotex-Light.otf');
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000;
  color: white;
  font-family: Kotex-Bold;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  scroll-behavior: smooth;
}

.option {
  cursor: pointer;
  font-weight: 500;
  font-size: 20px;
  padding: 5px 10px;
  box-sizing: border-box;
  border-radius: 50px;
}

.video {
  width: 100%;
  height: auto;
}

.wrong {
  background-color: #fa000056;
  border: 2px solid rgb(169, 0, 0) !important;
}

.right {
  background-color: #e6007fda;
  border: 2px solid rgb(104, 187, 65) !important;
  color: white !important;
}

h1 {
  text-align: center;
}

h2 {
  font-weight: bold;
}
